import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import SEO from '../../components/seo';
import Layout from '../../components/en';

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      excerpt
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        title
        tags
        lead
        cover {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1200, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;

const Post = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const { html, frontmatter, excerpt } = data.markdownRemark;
  const { date, title, cover, lead, tags, path } = frontmatter;

  return (
    <Layout data={data} location={typeof window !== 'undefined' ? location : null}>
      <SEO
        title={`${title} - Furlan Snowboards`}
        description={lead || excerpt || ' '}
        pathname={path}
        article
        keywords={['snowboard', 'boards', 'versatility']}
      />
      <div className="postPage">
        <div className="jumbotron">
          <div className="container mb-3">
            <div className="row">
              <div className="col-lg-12">
                <Img fluid={cover.childImageSharp.fluid} alt="Furlan Snowboards" />
                <h1 className="mt-1">{title}</h1>
                {tags &&
                  tags.map((tag) => {
                    const upperTag = tag.charAt(0).toUpperCase() + tag.slice(1);
                    return (
                      <Link key={tag} to={`/en/tags/${tag}`}>
                        <span className="badge badge-pill badge-primary badge-xl">
                          {upperTag}
                        </span>
                      </Link>
                    );
                  })}
                <small className="ml-2">{date}</small>
              </div>
            </div>
          </div>
          <div className="container mb-1">
            <p className="lead">{lead}</p>
          </div>
          <div className="container" dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
        <div className="container page-links">
          <div className="row">
            <div className="col-6">
              {prev && (
                <Link to={prev.frontmatter.path}>
                  Previous
                  <h3>{prev.frontmatter.title}</h3>
                </Link>
              )}
            </div>
            <div className="col-6 text-right">
              {next && (
                <Link to={next.frontmatter.path}>
                  Next
                  <h3>{next.frontmatter.title}</h3>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
};
